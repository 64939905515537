import { Link } from "gatsby"
import React from "react"
import LazyImage from "../LazyImage"

const islandsWording = {
  el: "νησιά",
  en: "islands",
  it: "isole",
  ru: "остров",
}

const chunkArray = (arr, n) => {
  var chunkLength = Math.max(arr.length / n, 1)
  var chunks = []
  for (var i = 0; i < n; i++) {
    if (chunkLength * (i + 1) <= arr.length)
      chunks.push(arr.slice(chunkLength * i, chunkLength * (i + 1)))
  }
  return chunks
}

export default function FeaturedIslands({ region, islands }) {
  let mapImage = `/images/map-${region.slug}.jpg`

  let featuredIslands = islands.slice(0, 8)
  let otherIslands = islands.slice(9, islands.length)

  let chunks = chunkArray(featuredIslands, 2)
  let topIslands = chunks.length > 0 ? chunks[0] : []
  let bottomIslands = chunks.length === 2 ? chunks[1] : []

  return (
    <div className="container">
      <div className="region-section">
        <div className="map-container">
          <span className="region-title">{region.title}</span>
          <img
            className="map-image"
            src={mapImage}
            alt={"aktoploikos xartis gia " + region.title}
          />
        </div>

        <div className="island-container">
          {/* <Link to="/" className="more-options-link">Περισσότερα ></Link> */}
          <div className="island-row top">
            {topIslands
              .map(l => l.node.frontmatter)
              .map(l => {
                return (
                  <Link
                    className="region-island"
                    to={l.url}
                    key={"region-island-top-" + l.slug}
                  >
                    <LazyImage
                      className="island-thumbnail"
                      src={`/images/${l.slug}.jpg`}
                      alt={"aktoploika gia " + l.slug}
                    />
                    <div className="island-title">{l.title}</div>
                  </Link>
                )
              })}
          </div>

          <div className="island-row bottom">
            {bottomIslands
              .map(l => l.node.frontmatter)
              .map(l => {
                return (
                  <Link
                    className="region-island"
                    to={l.url}
                    key={"region-island-bottom-" + l.slug}
                  >
                    <LazyImage
                      className="island-thumbnail"
                      src={`/images/${l.slug}.jpg`}
                      alt={"aktoploika " + l.slug}
                    />
                    <div className="island-title">{l.title}</div>
                  </Link>
                )
              })}
          </div>
        </div>
      </div>
      {islands.length > 0 && (
        <div className="region-islands-other">
          <h3 className="h2">
            {region.title} : {islands.length} {islandsWording[region.locale]}
          </h3>
          <div className="others-list">
            {islands
              .map(l => l.node.frontmatter)
              .map(l => {
                return (
                  <Link
                    className="other-island-link"
                    to={l.url}
                    key={"region-island-other-" + l.slug}
                    title={l.metatitle}
                  >
                    <span className="other-island-title">{l.title}</span>
                  </Link>
                )
              })}
          </div>
        </div>
      )}
    </div>
  )
}
