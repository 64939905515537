import { Link } from "gatsby"
import React from "react"

export default function IslandsSidebar({ region, islands }) {
  
  let mapImage = `/images/map-${region.slug}.jpg`;
  return (
    <div className="islands-sidebar">
      <div className="map-container">
        <span className="region-title">{region.title}</span>
        <img className="map-image" src={mapImage} alt={'xartis dromologiwn gia ' + region.title}/>
      </div>
  </div>
  )
}
    