import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Breadcrumbs from "../components/seo/breadcrumbs"
import { getHrefLangs } from "../core/QueryTransformer"
import RegionIslands from "../components/lists/region"
import IslandsSidebar from "../components/lists/islandsSidebar"

import rehypeReact from "rehype-react"
import Bubble from "../components/bubble"
import SimpleBox from "../components/simple-box"

const renderAst = new rehypeReact({
  createElement: React.createElement,
  components: { 
    bubble: Bubble,
    box: SimpleBox
  },
}).Compiler

export default function Template({ data, pageContext: { pages, locale, home } }) {

  const { regionPost, regionLocations, hreflangEdges, homePage } = data

  const { frontmatter, htmlAst } = regionPost
  let hreflangs = getHrefLangs(hreflangEdges.edges)

  let breadcrumbs = [
    {
      url: homePage.frontmatter.url,
      title: homePage.frontmatter.title,
    },
    {
      url: regionPost.frontmatter.url,
      title: regionPost.frontmatter.title,
    },
  ]

  return (
    <Layout
      hreflangs={hreflangs}
      pages={pages.filter(page => page.locale === frontmatter.locale)}
      title={frontmatter.heading}
      description={frontmatter.description}
      locale={locale}
      home={home}
      from={frontmatter.from}
      to={frontmatter.to}
    >
      <SEO
        title={frontmatter.metatitle}
        hreflangs={hreflangs}
        locale={frontmatter.locale}
        description={frontmatter.metadescription}
      />
      <Breadcrumbs breadcrumbs={breadcrumbs}></Breadcrumbs>

      <div className="main-body">
      <section className="container">
        <div className="row">
          <div className="col-8">
            {/* <div
              className="region-text"
              dangerouslySetInnerHTML={{ __html: html }}
            ></div> */}
            {
              renderAst(htmlAst)
            }
          </div>
          <div className="col-4">
            <div className="sidebar">
              <IslandsSidebar
                region={frontmatter}
                islands={regionLocations.edges}
              ></IslandsSidebar>
            </div>
          </div>
        </div>
      </section>

      <section className="section-dark">
        <RegionIslands
          region={frontmatter}
          islands={regionLocations.edges}
        ></RegionIslands>
      </section>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($url: String!, $locale: String!, $slug: String!, $region: String!) {
    regionPost: markdownRemark(
      frontmatter: { url: { eq: $url }, locale: { eq: $locale } }
    ) {
      # html
      htmlAst
      frontmatter {
        title
        heading
        metatitle
        url
        locale
        region
        type
        description
        metadescription
        slug
        from
        to
        #featuredImage {
        #  childImageSharp {
        #    fluid(maxWidth: 800) {
        #      ...GatsbyImageSharpFluid
        #    }
        #  }
        #}
      }
    }
    homePage: markdownRemark(
      frontmatter: { locale: { eq: $locale }, type: { eq: "homepage" } }
    ) {
      frontmatter {
        title
        url
      }
    }
    regionLocations: allMarkdownRemark(
      # limit: 6
      filter: {
        frontmatter: {
          region: { eq: $region }
          locale: { eq: $locale }
          type: { eq: "location" }
        }
      }
    ) {
      edges {
        node {
          frontmatter {
            title
            metatitle
            locale
            region
            type
            url
            slug
            #featuredImage {
            #  childImageSharp {
            #    fluid(maxWidth: 240) {
            #      ...GatsbyImageSharpFluid
            #    }
            #  }
            #}
          }
        }
      }
    }
    hreflangEdges: allMarkdownRemark(
      filter: { frontmatter: { slug: { eq: $slug } } }
    ) {
      edges {
        node {
          frontmatter {
            url
            locale
          }
        }
      }
    }
  }
`
